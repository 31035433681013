<template>
  <div
    v-if="contactCustomerServiceErrorScreen"
    class="fixed size-full flex justify-center items-center left-0 top-0 z-[5]"
  >
    <client-only>
      <div class="bg-red opacity-25 absolute size-full" />
      <div class="bg-white h-min-content relative px-6 py-12 text-center flex flex-col">
        <template v-if="!hasSent">
          <h5 class="text-red font-medium">
            {{ $t('booking.customer_data_error_screen.title') }}
          </h5>
          <AgentComponent>
            <div>
              Felmeddelande:
            </div>
            <div>
              {{ contactCustomerServiceErrorScreen.errorMessage }}
            </div>
            <template #noAgent>
              <div class="mt-4">
                <div class="text-sm">
                  {{ subtitle }}
                </div>
              </div>
              <div class="inline-flex flex-col text-left mt-6">
                <div class="flex justify-center">
                  <PhoneCountrySelect
                    class="!w-[110px]"
                    :value="phoneCountryCode"
                    autocomplete="off"
                    :dial-code="true"
                    @input="selectPhoneCountry"
                  />
                  <input
                    v-model="phone"
                    class="px-2 border relative"
                    placeholder="Telefonnummer"
                    :class="v$.phone.$invalid && !preventInvalidVisible ? 'border-danger' : 'border-gray border-l-0'"
                  />
                  <button
                    class="grow py-2 px-4"
                    :class="[$isSol ? 'bg-orange' : 'bg-blue text-white', { 'opacity-50': v$.phone.$invalid }]"
                    :disabled="v$.phone.$invalid"
                    @click="sendManualBookingRequest"
                  >
                    {{ $t('booking.customer_data_error_screen.sendbutton') }}
                  </button>
                </div>
              </div>
            </template>
          </AgentComponent>
        </template>
        <div v-else>
          <h4 class="mb-1 px-12 text-blue">
            {{ $t('booking.customer_data_error_screen.sent-title') }}
          </h4>
          <span>
            {{ $t('booking.customer_data_error_screen.sent-subtitle') }}
          </span>
        </div>
        <nuxt-link
          v-if="goBackPath"
          class="bg-transparent text-blue mt-6 pt-4 underline"
          :to="goBackPath"
          @click.prevent="clearErrorOnGoBack"
        >
          {{ $t('booking.customer_data_error_screen.goback') }}
        </nuxt-link>
      </div>
    </client-only>
  </div>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

defineProps({
  goBackPath: {
    type: String,
    default: null,
  },
})

const localeStore = useLocaleStore()

const {
  locale,
} = storeToRefs(localeStore)

const bookStore = useBookStore()
const {
  contactCustomerServiceErrorScreen,
  stateObjects,
} = storeToRefs(bookStore)

const phoneCountryCode = ref(0)
const selectPhoneCountry = (countryCode) => {
  phoneCountryCode.value = countryCode.dial_code
}

const phone = ref('')

const preventInvalidVisible = ref(true)
const hasSent = ref(false)

const { t } = useI18n()

const v$ = useVuelidate({
  phone: {
    phoneNumberValidator: (value) => phoneNumberValidator(phoneCountryCode.value, value),
    required,
  },
}, { phone, phoneCountryCode })

watch(phone, (value) => {
  if (value && !v$.value.phone.$invalid) {
    preventInvalidVisible.value = false
  }
})

const clearErrorOnGoBack = () => {
  contactCustomerServiceErrorScreen.value = null
}

const { data } = await useAsyncData(async () => {
  const { data: isCustomerServiceOpenResponse } = await apiFetch(`/${locale.value}/customer-service/is-open`)

  return {
    isCustomerServiceOpen: isCustomerServiceOpenResponse.is_open,
  }
}, { server: false, lazy: true  })
const isCustomerServiceOpen = computed(() => data.value?.isCustomerServiceOpen)

const subtitle = computed(() => isCustomerServiceOpen.value
  ? t('booking.customer_data_error_screen.subtitle-open')
  : t('booking.customer_data_error_screen.subtitle-not-open')
)

const sendManualBookingRequest = async () => {
  preventInvalidVisible.value = false
  if (v$.value.phone.$invalid) {
    return
  }

  await apiFetch(`/${locale.value}/booking/manual-request`, {
    method: 'POST',
    body: {
      phone: phone.value,
      phoneCountryCode: phoneCountryCode.value,
      state: stateObjects.value.pop(),
      fromUrl: window.location.href,
    },
  })

  hasSent.value = true
}
</script>