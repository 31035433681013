// Translated
// Migrated
<template>
  <NuxtLayout :name="setLayout">
    <BookContactCustomerServiceErrorScreen
      :go-back-path="comingFromRoute"
    />

    <div class="flex flex-wrap book-trip pb-12">
      <div
        v-if="hydrated"
        class="w-12/12"
      >
        <div
          v-if="tripinfo && (!route.params.step || route.params.step != 6)"
          class="mb-4 mt-4 lg:mt-0 h4"
        >
          {{ localeDateFormat(tripinfo.tripdate) }} {{ tripinfo.tripname }}
        </div>
        <Wizard
          class="mb-4"
          :step="wizardstep"
          :steps="steps"
        />
      </div>
      <div
        v-show="loading || pending"
        class="w-12/12 py-12 mt-12 text-center"
      >
        <p v-if="route.params.step === 1 || !route.params.step">
          <strong data-i18n="bookLoadingMessageSearchingForRooms">
            {{ $t('bookLoadingMessageSearchingForRooms') }}
          </strong>
        </p>
        <p v-if="route.params.step === 2">
          <strong data-i18n="bookLoadingMessageSubmittingRooms">
            {{ $t('bookLoadingMessageSubmittingRooms') }}
          </strong>
        </p>
        <p v-if="route.params.step === 3">
          <strong data-i18n="bookLoadingMessageSendingTravelerInformation">
            {{ $t('bookLoadingMessageSendingTravelerInformation') }}
          </strong>
        </p>
        <p v-if="route.params.step === 4">
          <strong data-i18n="bookLoadingMessageGettingPayment">
            {{ $t('bookLoadingMessageGettingPayment') }}
          </strong>
        </p>
        <Loading />
        <div
          v-if="loadingLong"
          class="my-4"
        >
          <strong data-i18n="bookLoadingMessageLongTime">
            {{ $t('bookLoadingMessageLongTime') }}
          </strong>
        </div>
      </div>
      <div
        v-if="initError"
        class="w-12/12 py-12 mt-12 text-center"
      >
        <div
          class="alert alert-danger"
          data-i18n="serverError"
        >
          {{ $t('serverError') }}
        </div>
      </div>
      <client-only>
        <div
          v-if="!initError && tripinfo"
          v-show="!loading && !pending"
          class="w-12/12"
        >
          <LazyStepError v-if="activeStep === 'error'" />
          <LazyStep1
            v-if="activeStep === 0"
            @next="handleCustomerData"
          />
          <LazyStep2Rooms
            v-if="activeStep === 1 && !tripinfo.hoteltype_needed"
            @next="handleRoomData"
          />
          <LazyStep2Hotels
            v-if="activeStep === 1 && tripinfo.hoteltype_needed"
            @next="handleRoomData"
          />
          <LazyStep4
            v-if="activeStep === 3 || activeStep === 4"
            :payment-active="activeStep === 4"
            @next="getPayment"
            @confirm-price-increase="goToStep(5)"
          />
          <LazyStep3Address
            v-if="activeStep === 2"
            @next="handleAddressData"
          />
          <LazyStep5Payment
            v-if="activeStep === 4"
            ref="step5"
            :key="paymentKey"
            @error="goToStep(4, true)"
            @cancel="goToStep(4, true)"
            @reload-payment="getPayment"
          />
          <LazyStep6Final v-if="activeStep === 5" />
        </div>
      </client-only>
      <b-modal
        ref="errorModal"
        v-model="showModal"
        centered
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        body-class="no-padding"
        :hide-footer="true"
        :hide-header="true"
        size="lg"
      >
        <div class="alert alert-danger mb-0">
          <div class="flex items-center">
            <h3 class="mb-0">
              <fa
                class="mr-4"
                icon="info-circle"
              />
            </h3>
            <span class="grow">
              {{ error }}
            </span>
            <button
              class="btn btn-blue ml-4"
              type="button"
              data-i18n="ok"
              @click="$refs['errorModal'].hide()"
            >
              {{ $t('ok') }}
            </button>
          </div>
        </div>
      </b-modal>
      <b-modal
        v-if="charterTimedout"
        ref="infoModal"
        visible
        centered
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        body-class="no-padding"
        :hide-footer="true"
        :hide-header="true"
        size="lg"
      >
        <div class="alert mb-0">
          <div class="flex items-center">
            <h3 class="mb-0">
              <fa
                class="mr-4"
                icon="info-circle"
              />
            </h3>
            <div
              class="grow"
              data-i18n="charterTimedout"
            >
              {{ $t('charterTimedout') }}
            </div>
            <button
              class="btn btn-blue ml-4"
              type="button"
              data-i18n="charterRestart"
              @click="restart"
            >
              {{ $t('charterRestart') }}
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </NuxtLayout>
</template>

<script setup>
import { callWithNuxt } from '#app'

const { urls: localeURLs } = useLocale()
const { trackInitiateCheckout } = useTracking()
const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const {
  $sentryCaptureException,
  $sentryCaptureMessage,
  $sentrySetContext,
} = useNuxtApp()

const localeStore = useLocaleStore()
const charterStore = useCharterStore()
const bookStore = useBookStore()
const rootStore = useRootStore()
const whitelabelStore = useWhitelabelStore()

const {
  previousRoute,
} = storeToRefs(rootStore)

const { whitelabel: isWhitelabel } = storeToRefs(whitelabelStore)

const { localeDateFormat } = useDate()

const {
  tripinfo,
  error: bookError,
  activeStep,
  isRoundtrip,
} = storeToRefs(bookStore)

const loading = ref(true)
const loadingLong = ref(false)
const loadingTimer = ref(null)
const initError = ref(null)
const error = ref(null)
const showModal = ref(false)
const paymentKey = ref(1)
const noRoomFound = ref(false)
const steps = ref([
  t('bookStepRoomInfo'),
  t('bookStepAddress'),
  t('bookStepPayment'),
  t('bookStepFinal'),
])
const hydrated = ref(false)

const initialPrice = route.query.price
const singlePrice = route.query.singlePrice

const charterTimedout = computed(() => charterStore.timedout)

const setLayout = computed(() => {
  if (isWhitelabel.value) {
    return 'charter-whitelabel'
  }

  return isSol()
    ? 'charter-default'
    : 'noheader-layout'
})

const wizardstep = computed(() => {
  const step = parseInt(route.params.step)

  if (step <= 2 || !step) {
    return 1
  } else if (step === 5 || step === 4) {
    return 3
  } else if (step === 6) {
    return 4
  }

  return 2
})

watchEffect(() => {
  if (!showModal.value) {
    bookStore.SET_ERROR(null)
  }
})

const comingFromRoute = ref(null)

onBeforeMount(() => {
  let fullPath = previousRoute.value?.fullPath || ''

  if (fullPath.includes('showRooms')) {
    const url = new URL(fullPath, window.location.origin)
    url.searchParams.delete('showRooms')
    fullPath = url.pathname + url.search
  }
  comingFromRoute.value = fullPath
})

const { pending } = await useAsyncData(async () => {
  if (initError.value) {
    return
  }

  localeStore.SET_PAGE_WIDE_TRANSLATIONS(createPageWideTranslation(['bookStepRoomInfo', 'bookStepAddress', 'bookStepPayment', 'bookStepFinal']))

  if (!rootStore.charterAirports.length) {
    await rootStore.fetchCharterAirports()
  }

  if (!route.params.step) {
    try {
      await bookStore.init({ tripid: route.params.tripid, initialPrice, singlePrice })
    } catch (e) {
      if (e.data?.error) {
        let level = 'error'
        if (!route?.params?.tripid || route?.params?.tripid === 'undefined') {
          level = 'fatal'
        } else if (['Resan har redan gått', 'Resan är inte tillgänglig'].includes((e?.data?.message || '')?.trim())) {
          level = 'info'
        }

        $sentrySetContext('details', JSON.stringify({
          data: e?.data,
          level,
          initialPrice,
          singlePrice,
          exceptionMessage: e?.message,
          internalMessage: e?.data?.message,
          tripId: route?.params?.tripid,
        }))
        $sentryCaptureMessage(`book/init ${level}`, {
          level,
          tags: {
            type: 'UX',
            source: 'booking',
          },
        })
      } else {
        $sentryCaptureException(e)
      }

      initError.value = true

      return
    }

    return navigateTo({ replace: true, params: { ...route.params, step: 1 }, query: route.query })
  }

  if (!isRoundtrip.value) {
    steps.value.shift()
    steps.value.unshift(t('searchButtonText'))
  }

  // Enable browser navigation buttons
  const step = parseInt(route.params.step)
  if (step - 1 <= bookStore.highestStep && step >= 1) {
    bookStore.SET_STEP(step - 1)
    bookStore.backupState()
  }

  if (bookStore.bookinfo?.success && tripinfo.value?.tripid === route.params.tripid) {
    loading.value = true
    bookStore.SET_STEP('success')
    return callWithNuxt(nuxtApp, navigateTo, [`/booking-confirmation/${bookStore.bookinfo.ocr}?k=${bookStore.bookinfo.customerId}`]).catch(() => { })
  } else if (
    (step === 1 && activeStep.value < 0)
    || (tripinfo.value?.tripid !== route.params.tripid && !isSol())
  ) {
    bookStore.SET_STEP(-1)
    return callWithNuxt(nuxtApp, navigateTo, [`${localeStore.urls.book}/${route.params.tripid}?price=${route.query.price || 0}`]).catch(() => {})
  } else if (
    (
      (activeStep.value > 1 || activeStep.value !== route.params.step)
      && !bookStore.tripinfo
    )
    || !bookStore.stateObjects.length
  ) {
    bookStore.SET_STEP('error')
  }
}, { server: false, lazy: true })

useHead({
  title: () => `${t('bookTitle')}`,
})

onMounted(() => {
  router.beforeEach((to, from, next) => {
    if (!from.fullPath.startsWith(localeURLs.book)) {
      bookStore.SET_ROOMS(null)
    }
    next()
  })
  hydrated.value = true
  loading.value = false

  if (bookError.value) {
    error.value = bookError.value
    showModal.value = true
  }

  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
})

const handleCustomerData = async (data) => {
  setLoading(true)
  noRoomFound.value = false
  let nextStep = 2

  try {
    if (tripinfo.value.roomstypes_needed) {
      await bookStore.roomTypes(data)
    } else {
      await bookStore.customerData(data)

      nextStep = 3
    }

    setLoading(false)
    goToStep(nextStep)
  } catch (e) {
    setLoading(false)

    if (e.data?.message === 'state_expired') {
      return
    }

    if (e.data?.waitinglist) {
      return
    }

    if (e.data?.error) {
      $sentryCaptureMessage('book/handleCustomerData error', {
        level: 'error',
        contexts: {
          details: e.data,
        },
        tags: {
          type: 'UX',
          source: 'booking',
        },
      })

      setLoading(false)
      error.value = e.data.message || t('serverError')
      showModal.value = true
      return
    }

    $sentryCaptureException(e)

    setLoading(false)
    error.value = t('serverError')
    showModal.value = true
  }
}

const handleRoomData = async (data) => {
  try {
    setLoading(true)
    await bookStore.customerData(data)

    setLoading(false)
    goToStep(3)
  } catch (e) {
    setLoading(false)

    if (e.data?.message === 'state_expired') {
      return
    }

    if (e.data?.error) {
      $sentryCaptureMessage('book/customerData (roomData) error', {
        level: 'error',
        contexts: {
          details: e.data,
        },
        tags: {
          type: 'UX',
          source: 'booking',
        },
      })
    } else {
      $sentryCaptureException(e)
    }

    error.value = e.data?.message || t('serverError')
    showModal.value = true
  }
}

const handleAddressData = async (data) => {
  try {
    setLoading(true)
    await bookStore.addressData(data)

    trackInitiateCheckout({
      id: tripinfo.value.triptypeid ?? tripinfo.value.server_tripid,
      name: tripinfo.value.tripname,
      value: tripinfo.value.price,
    })

    setLoading(false)
    goToStep(4)
  } catch (e) {
    setLoading(false)

    if (e.data?.message === 'state_expired') {
      return
    }

    if (e.data?.error) {
      $sentryCaptureMessage('book/addressData error', {
        level: 'error',
        contexts: {
          details: e.data,
        },
        tags: {
          type: 'UX',
          source: 'booking',
        },
      })
    } else {
      $sentryCaptureException(e)
    }

    error.value = e.data?.message || t('serverError')
    showModal.value = true
  }
}

const getPayment = async (data) => {
  try {
    setLoading(true)
    bookStore.SET_PRICEINCREASE(0)

    const result = await bookStore.paymentData({
      final_confirmation: data?.final_confirmation,
      skip: !!data?.skip,
    })

    if (!result.bambora_token) {
      const finalresult = await bookStore.finalize()

      if (data.onSuccess?.length) {
        await Promise.all(data.onSuccess.map(func => func()))
      }

      return navigateTo(`/booking-confirmation/${finalresult.ocr}?k=${finalresult.customerId}`)
    } else if (parseInt(result.priceIncrease) > 0) {
      bookStore.SET_PRICEINCREASE(result.priceIncrease)
      setLoading(false)
      return
    } else {
      goToStep(5)
    }
  } catch (e) {
    if (e.data?.message === 'state_expired') {
      return
    }

    if (e.data?.error) {
      $sentryCaptureMessage('book/paymentData error', {
        level: 'error',
        contexts: {
          details: e.data,
        },
        tags: {
          type: 'UX',
          source: 'booking',
        },
      })
    } else {
      $sentryCaptureException(e)
    }

    error.value = e.data?.message || t('serverError')
    showModal.value = true
  }

  setLoading(false)
}

const setLoading = (state) => {
  loading.value = state
  loadingLong.value = false

  clearTimeout(loadingTimer.value)

  if (loading.value) {
    loadingTimer.value = window.setTimeout(() => {
      loadingLong.value = true
    }, 8000)

    try {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } catch {
      window.scrollTo(0, 0)
    }
  }
}

const goToStep = (step, replace = false) => {
  if (!window.location.pathname.includes(localeURLs.book)) {
    return
  }

  const url = `${localeURLs.book}/${route.params.tripid}/${step || ''}${initialPrice ? '?price=' + initialPrice : ''}${singlePrice ? '&singlePrice=' + singlePrice : ''}`

  try {
    navigateTo(url, { replace })
  } catch {
    //
  }

  bookStore.SET_HIGHESTSTEP(step - 1)
  // bookStore.SET_STEP(step - 1)
  // bookStore.backupState()
}

const restart = async () => {
  if (!isSol()) {
    charterStore.SET_TIMEDOUT(false)
    goToStep(0)
    return
  }

  const searchOptions = deepClone({
    selectedAirport: charterStore.selectedAirport,
    selectedDestination: charterStore.selectedDestination,
    selectedDestinationL1: charterStore.selectedDestinationL1,
    rooms: deepClone(charterStore.rooms),
    selectedStartDate: deepClone(charterStore.selectedStartDate),
  })

  charterStore.search(searchOptions)
  const options = await charterStore.getURLParameters(searchOptions)
  navigateTo(`${localeURLs.chartersearch}?${options}`)
}
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/_form";
@import "@layers/web/assets/scss/pages/_book";
@import "@layers/web/assets/scss/modules/charter/_charter-results";
</style>
